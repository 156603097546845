import ApiSistema from '@/core/conectores/ApiSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ICategoria } from '@/models/Entidades/Cadastros/ICategoria';

/**
 * Serviço de Categoria de Produtos
 * Fornece todas regras de negócios e lógicas relacionado a Categoria de Produtos.
 */

class ServicoCategoriaProduto implements IServicoBase<ICategoria> {
  endPoint = 'categorias';

  apiSistema = new ApiSistema();

  public requisicaoSistema(): void {
    this.apiSistema = new ApiSistema(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiSistema.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiSistema.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: ICategoria): string {
    return objeto.nome;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.apiSistema.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async incluir(categoriaProdutos: ICategoria): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}`, categoriaProdutos);
    return result.data;
  }

  public async alterar(categoriaProdutos: ICategoria): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.put(`${this.endPoint}/${categoriaProdutos.codigo}`, categoriaProdutos);
    return result.data;
  }

  public async obter(codigo: number): Promise<ICategoria> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }
}
export default ServicoCategoriaProduto;
