
import {
  defineComponent, computed, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '../Icone.vue';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { EOrdenacao } from '@/core/models/Enumeradores/EOrdenacao';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';

export default defineComponent({
  name: 'OrdenacaoBusca',
  props: {
    propriedadesOrdenacao: {
      type: Array as () => IPropriedadeConsulta[],
      required: true,
    },
    ordenacao: {
      type: Array as () => IOrdenacao[],
      required: true,
    },
  },
  emits: ['update:ordenacao', 'ordenacaoAlterada'],
  components: {
    Icone,
    draggable,
  },
  setup(props, { emit }) {
    const state = reactive({
    });

    const computedOrdenacao = computed({
      get: () => props.ordenacao,
      set: (val) => {
        emit('update:ordenacao', val);
      },
    });

    function alterarOrdem(identificador: string, ordem:string) {
      const index = computedOrdenacao.value.findIndex((c) => c.identificador === identificador);
      if (index >= 0) {
        if (computedOrdenacao.value[index].ordem === ordem) {
          computedOrdenacao.value.splice(index, 1);
        } else {
          computedOrdenacao.value[index].ordem = ordem;
        }
      } else {
        computedOrdenacao.value.push({ identificador, descricao: '', ordem } as IOrdenacao);
      }
      emit('ordenacaoAlterada');
    }

    function atualizarOrdenacao() {
      emit('ordenacaoAlterada');
    }

    function obtemOrdem(identificador:string):string {
      const index = computedOrdenacao.value.findIndex((c) => c.identificador === identificador);
      if (index >= 0) {
        return computedOrdenacao.value[index].ordem;
      }

      return '';
    }

    return {
      props,
      state,
      ETipoDado,
      EOrdenacao,
      computedOrdenacao,
      alterarOrdem,
      atualizarOrdenacao,
      obtemOrdem,
    };
  },
});
