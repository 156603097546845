import ApiSistema from '@/core/conectores/ApiSistema';
import { IUsuario } from '@/models/Entidades/Sistema/Usuarios/IUsuario';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import storeSistema from '@/store/storeSistema';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOControlePermissoes } from '@/models/DTO/Sistema/Usuarios/IDTOControlePermissoes';
/**
 * Serviço de Usuários
 * Fornece todas regras de negócios e lógicas relacionado aos Usuários.
 */

class ServicoUsuario implements IServicoBase<IUsuario> {
  endPoint = 'usuarios';

  apiSistema = new ApiSistema();

  public requisicaoSistema(): void {
    this.apiSistema = new ApiSistema(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaTeste = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
    return listaTeste;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigo?: boolean): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiSistema.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    let parametroApresentarEstado = '';
    if (UtilitarioGeral.objetoValido(apresentarCodigo)) {
      parametroApresentarEstado = `&apresentarCodigo=${apresentarCodigo}`;
    }
    const result: any = await this.apiSistema.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}${parametroApresentarEstado}`);
    return result.data;
  }

  public async consultaRapidaPorCodigos(codigos: number[], contratantes: number[], apresentarCodigo?: boolean): Promise<IItemConsultaRapida[]> {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {} as IParametrosConsultaRapida;
    parametrosConsultaRapida.filtrarPorVariosCodigos = true;
    parametrosConsultaRapida.contratantes = contratantes;
    parametrosConsultaRapida.valores = [];

    codigos.forEach((codigoUsuario) => {
      if (parametrosConsultaRapida.valores !== undefined) {
        parametrosConsultaRapida.valores.push(codigoUsuario.toString());
      }
    });

    const result = await this.consultaRapida(parametrosConsultaRapida, apresentarCodigo);
    return result;
  }

  public async obterUsuariosPorCodigosConsultaRapida(codigos: number[]): Promise<IItemConsultaRapida[]> {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      valor: '',
      recursoAssociado: '',
      filtrarPorVariosCodigos: true,
      quantidadeRegistros: 99999,
    };
    parametrosConsultaRapida.valores = [];
    codigos.forEach((codigo) => {
      if (parametrosConsultaRapida.valores !== undefined) {
        parametrosConsultaRapida.valores.push(codigo.toString());
      }
    });

    return this.apiSistema.consultaRapida(this.endPoint, parametrosConsultaRapida);
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiSistema.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiSistema.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IUsuario): string {
    return objeto.nome;
  }

  public async incluir(usuario: IUsuario): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}`, usuario);
    return result.data;
  }

  public async alterar(usuario: IUsuario): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.put(`${this.endPoint}/${usuario.codigo}`, usuario);
    return result.data;
  }

  public async obter(codigo: number): Promise<IUsuario> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterPermissoesControle(codigo: number, empresa: number): Promise<IDTOControlePermissoes[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}/empresa/${empresa}/permissoes/controle`);
    return result.data;
  }

  public async enviarImagem(arquivo: any): Promise<IRetornoArquivo> {
    const data = new FormData();
    data.append('arquivo', arquivo);
    const result: any = await this.apiSistema.upload(`${this.endPoint}/${storeSistema.getters.codigoUsuarioAutenticado()}/contratante/${storeSistema.getters.codigoContratanteOperacao()}/imagem`, data);
    return result.data;
  }

  public async removerImagem(linkArquivo: string): Promise<IRetornoArquivo> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/${storeSistema.getters.codigoUsuarioAutenticado()}/contratante/${storeSistema.getters.codigoContratanteOperacao()}/imagem?linkArquivo=${linkArquivo}`);
    return result.data;
  }

  public async validarControlePermissoes(usuario: number, empresa: number): Promise<boolean> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/validar-controle-permissoes/${usuario}/empresa/${empresa}`);
    return result.data;
  }
}
export default ServicoUsuario;
