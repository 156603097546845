import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ce0c7b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ss-ordenacao-busca" }
const _hoisted_2 = { class: "ant-row ss-card-ordenacao-busca" }
const _hoisted_3 = { class: "ant-col ant-col-xl-21 ant-col-xs-21" }
const _hoisted_4 = { class: "ss-titulo-etapa" }
const _hoisted_5 = { class: "ant-col ant-col-xl-3 ant-col-xs-3" }
const _hoisted_6 = { class: "ss-icones-ordenacao-busca" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      modelValue: _ctx.props.propriedadesOrdenacao,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.propriedadesOrdenacao) = $event)),
      "item-key": "campo",
      handle: ".ss-mover",
      onSort: _ctx.atualizarOrdenacao
    }, {
      item: _withCtx(({ element }) => [
        (_openBlock(), _createElementBlock("div", {
          key: element.identificador,
          class: "ss-mover ss-sombra-suave",
          title: "Clique e arraste para mover"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(element.apresentacao), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("a", {
                  class: _normalizeClass(_ctx.obtemOrdem(element.identificador) === 'ASC'?'ss-ordenacao-busca-ativa':''),
                  title: "Crescente",
                  onClick: ($event: any) => (_ctx.alterarOrdem(element.identificador, 'ASC'))
                }, [
                  _createVNode(_component_icone, { nome: "ordem-crescente" })
                ], 10, _hoisted_7),
                _createElementVNode("a", {
                  class: _normalizeClass(_ctx.obtemOrdem(element.identificador)=== 'DESC'?'ss-ordenacao-busca-ativa':''),
                  title: "Decrescente",
                  onClick: ($event: any) => (_ctx.alterarOrdem(element.identificador, 'DESC'))
                }, [
                  _createVNode(_component_icone, { nome: "ordem-decrescente" })
                ], 10, _hoisted_8)
              ])
            ])
          ])
        ]))
      ]),
      _: 1
    }, 8, ["modelValue", "onSort"])
  ]))
}