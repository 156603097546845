/**
 * Serviço ServicoCompartilhado
 * Centraliza e organiza regras essências para efetuar a consulta rápida do sistema
 */

import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import ServicoCategoria from '../Cadastros/ServicoCategoria';
import ServicoMarca from '../Cadastros/ServicoMarca';
import ServicoNcm from '../Cadastros/ServicoNcm';
import ServicoUsuario from './ServicoUsuario';

class ServicoCompartilhado {
  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    let retornoConsulta: IItemConsultaRapida[] = [];

    switch (parametrosConsultaRapida.recursoAssociado) {
      case 'CADASTRO_USUARIOS':
        retornoConsulta = await new ServicoUsuario().consultaRapida(parametrosConsultaRapida, true);
        break;

      case 'CADASTRO_CATEGORIAS':
        retornoConsulta = await new ServicoCategoria().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_MARCAS':
        retornoConsulta = await new ServicoMarca().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_NCMS':
        retornoConsulta = await new ServicoNcm().consultaRapida(parametrosConsultaRapida);
        break;

      default:
        break;
    }
    return retornoConsulta;
  }
}

export default ServicoCompartilhado;
